import {
    fetchMemberNavigation,
    fetchChronicCareToken,
    chronicCareTokenLogout,
} from '@td/websdk';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import format from 'date-fns/format';
import ChatAPI from '@teladoc/fe-ccm/ui/chat/chat-api';
import {userUpdate} from '@teladoc/fe-ccm/ui/user/user-actions';
import UserAPI from '@teladoc/fe-ccm/ui/user/user-api';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import Arg from '@livongo/arg';
import {APIRegistry} from '@livongo/utilities/system/api';
import StorageUtils from '@livongo/utilities/system/storage';
import CommonUtils from '@/utils/common-utils';
import {
    BRAND_COLOR,
    MEMBER_ID,
    IMPERSONATION,
    TDOC_AUTH_TOKEN,
    ACCESS_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    EXPIRATION_TIME,
    ACCESS_TOKEN_TTL,
    FORMAT_DATETIME_NO_TZ,
    UN_LINKED_MEMBER,
    UN_LINKED_MEMBER_EXPIRATION,
    MEMBER_ID_PREV,
    MEMBER_AUTH_TOKEN_PREV,
    ACCESS_TOKEN_PREV,
    IS_TDOC_TOKEN_INVALID,
    DATA_LOAD_EXPIRATION,
    INITIAL_DATA_LOAD,
    NEXT_COACHING_SESSION,
    COACH_SESSION,
    BUNDLE_IDENTIFIER,
    SEND_MESSAGE_ONCE,
    MESSAGE_COUNTER_EXPIRATION,
    USER_LOCALE_COOKIE,
    MEAL_TYPE,
    FOOD_UI,
    MEAL_ID,
} from '@/config';
import {getStore} from '@/store';
import ProgramUtils from '@/utils/program-utils';
import {redirectToLoginPage, getUpdatedWebsdkURL} from '@/utils/url-utils';

const MYSTRENGTH_AXIOS_KEY = 'myStrength';

const myStrengthAxiosInstance =
    APIRegistry.get(MYSTRENGTH_AXIOS_KEY) ||
    APIRegistry.create({
        name: MYSTRENGTH_AXIOS_KEY,
        config: {baseUrl: process.env.MYSTRENGTH_API_URL},
    });

let timestamp;
let refreshTimeout;
let expires;
const nonLinkedMemberTtl = 1799;
const ccmStatus = {
    HAS_CQM: 1,
    DONT_HAVE_CQM: 0,
};
const memberType = {
    linked: 1,
    unlinked: 2,
};

const OneAppUserAPI = {
    // This is to check whether a nonlinked member has been redirected to CQM for enrollment and
    // if they have enrolled into any ccm programs successfully and they are redirect back here from
    // registration/signup site (https://signup.ABC.XYZ) then /sso api should be called to get the
    // access_token and refreshtoken
    checkForCqmEnrollment() {
        const referrer = document?.referrer?.toString().toLowerCase();
        const referrers = ['signup', 'upsell', 'program'];
        const hasCqm =
            referrers.filter(p1 => referrer.includes(p1))?.length > 0
                ? ccmStatus.HAS_CQM
                : ccmStatus.DONT_HAVE_CQM;

        return hasCqm;
    },

    /*
        This function will redirect the user back to the login page
        if the exception has a message of 'authentication'
    */
    redirectToLoginIfNeed(exception) {
        // This is when tdoc auth cookie is invalid
        if (
            exception &&
            exception.toString().toLowerCase().includes('authentication')
        ) {
            redirectToLoginPage(true);
        } else {
            // All other types of exceptions related to sso-ccm-token minting
            // if not succesful should still let oneapp go on with Telemed portion only
            return exception;
        }
    },

    /*
        This function return the current brand primary color
    */
    getBrandColor() {
        return CommonUtils.getCookie({key: BRAND_COLOR});
    },
    /*
        This function return the current member's ID
    */
    getMemberId() {
        return CommonUtils.getCookie({key: MEMBER_ID});
    },

    /*
        This function return the current member's tdoc auth token
    */
    getTdocAuthToken() {
        return CommonUtils.getCookie({key: TDOC_AUTH_TOKEN});
    },

    /*
        This function return the current Impersonation details
    */
    getImpersonationDetails() {
        const cookieValue = JSON.parse(
            CommonUtils.getCookie({key: IMPERSONATION})
        );

        return {
            impersonatorType: cookieValue?.impersonator_type,
            impersonatorId: cookieValue?.impersonator_type_id,
            impersonatedType: cookieValue?.impersonated_type,
            impersonatedId: cookieValue?.impersonated_type_id,
            impersonatedName: cookieValue?.impersonated_name,
        };
    },

    /*
        This function returns the previous login session's member tdoc auth token
    */
    getPrevMemberTdocAuthToken() {
        return CommonUtils.getLocalStorage(MEMBER_AUTH_TOKEN_PREV);
    },

    /*
        This function returns the current member's refresh token.
    */
    getCcmRefreshToken() {
        return CommonUtils.getCookie({key: REFRESH_TOKEN_COOKIE});
    },

    /*
        This function returns the current member's ccm access token
    */
    getCurrentCcmAccessToken() {
        return CommonUtils.getCookie({key: ACCESS_TOKEN_COOKIE});
    },

    /*
        This function the expiration time when to call the /sso endpoint
        again to get the access token from livongo system
    */
    getExpirationTime() {
        return CommonUtils.getLocalStorage(EXPIRATION_TIME);
    },

    /*
        This function returns the next time the /sso api call should be called for a member that is not linked
        in the Livongo system to check if they got linked by another method, then the /sso
        should return a ccm access_token
    */
    getNonLinkedMemberTime() {
        return CommonUtils.getLocalStorage(UN_LINKED_MEMBER_EXPIRATION);
    },

    /*
        This function returns a nonlinked member's description only.
    */
    getUnlinkedUser() {
        return CommonUtils.getLocalStorage(UN_LINKED_MEMBER);
    },

    /*
        This function returns the TTL for a CCM/Livongo token
    */
    getCurrentCcmAccessTokenTtl() {
        return CommonUtils.getLocalStorage(ACCESS_TOKEN_TTL);
    },

    /*
        This function returns the previous login session's ccm access token
    */
    getPrevMemberCcmAccessToken() {
        return CommonUtils.getLocalStorage(ACCESS_TOKEN_PREV);
    },

    /*
        This function return the PLI client bundle identifier
    */
    getBundleIdentifier() {
        return (
            CommonUtils.getCookie({key: BUNDLE_IDENTIFIER}) ||
            process.env.WEBSDK_BUNDLE_IDENTIFIER
        );
    },

    /*
        This function takes a member type and remove the cookies associated with it
    */
    removeCookieForMember(type) {
        if (type === memberType.linked) {
            CommonUtils.removeLocalStorage(INITIAL_DATA_LOAD);
            CommonUtils.removeLocalStorage(DATA_LOAD_EXPIRATION);
            CommonUtils.removeLocalStorage(EXPIRATION_TIME);
            CommonUtils.removeLocalStorage(ACCESS_TOKEN_TTL);
            CommonUtils.removeLocalStorage(MEMBER_ID_PREV);
            CommonUtils.removeLocalStorage(MEMBER_AUTH_TOKEN_PREV);
            CommonUtils.removeLocalStorage(ACCESS_TOKEN_PREV);
            CommonUtils.removeLocalStorage(NEXT_COACHING_SESSION);
            CommonUtils.removeLocalStorage(COACH_SESSION);
            CommonUtils.removeLocalStorage(SEND_MESSAGE_ONCE);
            CommonUtils.removeLocalStorage(MESSAGE_COUNTER_EXPIRATION);
            CommonUtils.removeLocalStorage(MEAL_TYPE);
            CommonUtils.removeLocalStorage(FOOD_UI);
            CommonUtils.removeLocalStorage(MEAL_ID);
            CommonUtils.removeCookie(REFRESH_TOKEN_COOKIE);
            CommonUtils.removeCookie(ACCESS_TOKEN_COOKIE);
        } else if (type === memberType.unlinked) {
            CommonUtils.removeLocalStorage(UN_LINKED_MEMBER);
            CommonUtils.removeLocalStorage(UN_LINKED_MEMBER_EXPIRATION);
        }
    },

    /*
        This function sets up the CCM headers and ccm access token. It also
        loads ccm data with api calls
    */
    loadInitialData(promises, impersonationDetails) {
        const hasCqm = OneAppUserAPI.checkForCqmEnrollment();

        if (hasCqm === ccmStatus.HAS_CQM) {
            CommonUtils.removeLocalStorage(INITIAL_DATA_LOAD);
            CommonUtils.removeLocalStorage(DATA_LOAD_EXPIRATION);
        }
        UserUtils.updateHeaders({
            Authorization: `Bearer ${this.getCurrentCcmAccessToken()}`,
        });
        this.setCurrentCcmAccessToken(this.getCurrentCcmAccessToken());
        promises.push(
            UserAPI.loadInitialDataForOneApp({
                // eslint-disable-next-line camelcase
                response: {access_token: this.getCurrentCcmAccessToken()},
                isOneApp: true,
                isAuthenticated: !isEmpty(this.getTdocAuthToken()),
                impersonationInfo: impersonationDetails,
            })
        );
        OneAppUserAPI.refreshHelper(OneAppUserAPI.getCcmRefreshToken());
    },

    /*
        This function set current logged in member's login session's access token
    */
    setCurrentCcmAccessToken(token) {
        UserAPI.setCurrentAccessToken(token);
        CommonUtils.setCookie({key: ACCESS_TOKEN_COOKIE, value: token});
        CommonUtils.setLocalStorage({
            key: ACCESS_TOKEN_PREV,
            value: token,
        });
    },

    /*
        This function sets in the future the next time the /sso function should be call again
    */
    setRecheckTimeInTheFuture({cookieName, seconds}) {
        const minutesLess = 15;
        const futureExpirationTime = addMinutes(
            new Date(),
            Math.floor(seconds / 60) - minutesLess
        );

        CommonUtils.setLocalStorage({
            key: cookieName,
            value: format(futureExpirationTime, FORMAT_DATETIME_NO_TZ),
        });
    },

    /*
        This function sets the description of non linked member
    */
    setUnlinkedUser(value) {
        CommonUtils.setLocalStorage({
            key: UN_LINKED_MEMBER,
            value,
        });
    },

    /*
        This checks if the current/right now time is after the ccm expiration time,
        then the token should be 'invalid' so it Oneapp knows to call /sso api again
        to get a new ccm and refresh token.
    */
    isCcmTokenTtlValid() {
        const currentCcmTokenExpiration = new Date(this.getExpirationTime());
        const rightNow = new Date();

        return isBefore(rightNow, currentCcmTokenExpiration);
    },

    /*
        This checks if the current/right now time is after a predetermined future time, then
        OneApp should call the /sso api again even for a nonlinked member to check if they've
        have registered via cqm or some other means to have ccm programs.
    */
    isNotTimeToRecheckNonLinkedMember() {
        const futureDate = new Date(this.getNonLinkedMemberTime());
        const rightNow = new Date();

        return isBefore(rightNow, futureDate);
    },

    /*
        This is a check if the current application is not the default Teladoc Core branded application
    */
    isPLIBrand() {
        return (
            this.getBundleIdentifier() !== process.env.WEBSDK_BUNDLE_IDENTIFIER
        );
    },

    /*
        This function takes a usePreviousMember (bool) parameter to build a
        websdk param object with the previous logged in session or current session
    */
    generateWebSDKParams(usePrevMember) {
        const userLocale = CommonUtils.getCookie({key: USER_LOCALE_COOKIE});
        const country = CommonUtils.getCountry(userLocale);

        return {
            baseUrl: getUpdatedWebsdkURL(country),
            apiToken: process.env.WEBSDK_MEMBER_APP_API_TOKEN,
            authToken: usePrevMember
                ? CommonUtils.getLocalStorage(MEMBER_AUTH_TOKEN_PREV)
                : this.getTdocAuthToken(),
            bundleIdentifier: this.getBundleIdentifier(),
            authorizedMemberId: usePrevMember
                ? CommonUtils.getLocalStorage(MEMBER_ID_PREV)
                : this.getMemberId(),
        };
    },

    /*
        This function is to persist the tdoc generated cookies to have a longer life
        like the rest of the other cookies
    */
    persistMemberWebsiteGeneratedCookies() {
        if (this.getMemberId()) {
            CommonUtils.setCookie({
                key: MEMBER_ID,
                value: this.getMemberId(),
            });
            CommonUtils.setLocalStorage({
                key: MEMBER_ID_PREV,
                value: this.getMemberId(),
            });
        }

        if (this.getTdocAuthToken()) {
            CommonUtils.setCookie({
                key: TDOC_AUTH_TOKEN,
                value: this.getTdocAuthToken(),
            });
            CommonUtils.setLocalStorage({
                key: MEMBER_AUTH_TOKEN_PREV,
                value: this.getTdocAuthToken(),
            });
        }
    },

    /*
        This function is start the will call the /sso endpoint to get back a ccm auth token
        for a member.
    */
    initCcmFlow() {
        // This is to check whether a nonlinked member has been redirected to CQM for enrollment and
        // if they have enrolled into any ccm programs successfully and they are redirect back here from
        // registration/signup site (https://signup.ABC.XYZ) then /sso api should be called to get the
        // access_token and refreshtoken
        const hasCqm = OneAppUserAPI.checkForCqmEnrollment();
        const ccmMfaRedirect = Arg('from_registration');

        if (
            !ccmMfaRedirect &&
            hasCqm === ccmStatus.DONT_HAVE_CQM &&
            this.getUnlinkedUser() === 'no_active_member' &&
            this.isNotTimeToRecheckNonLinkedMember()
        ) {
            return Promise.resolve({});
        } else {
            return fetchChronicCareToken(this.generateWebSDKParams())
                .then(response => {
                    if (response?.access_token) {
                        UserUtils.updateHeaders({
                            Authorization: `Bearer ${response.access_token}`,
                        });
                        expires = response?.expires_in;
                        timestamp = response?.last_activity_timestamp;
                        this.setCurrentCcmAccessToken(response.access_token);
                        this.setRecheckTimeInTheFuture({
                            cookieName: EXPIRATION_TIME,
                            seconds: expires,
                        });
                        CommonUtils.setLocalStorage({
                            key: ACCESS_TOKEN_TTL,
                            value: expires,
                        });
                        OneAppUserAPI.removeCookieForMember(
                            memberType.unlinked
                        );
                        OneAppUserAPI.refreshHelper(response.refresh_token);
                        UserAPI.redirectIfNeeded();
                    } else if (response?.errorType === 'no_active_member') {
                        UserUtils.updateHeaders({
                            Authorization: '',
                        });
                        this.setUnlinkedUser(response.errorType);
                        this.setRecheckTimeInTheFuture({
                            cookieName: UN_LINKED_MEMBER_EXPIRATION,
                            seconds: nonLinkedMemberTtl,
                        });
                        OneAppUserAPI.removeCookieForMember(memberType.linked);
                    }
                    this.persistMemberWebsiteGeneratedCookies();

                    return response;
                })
                .catch(exception => {
                    return this.redirectToLoginIfNeed(exception);
                });
        }
    },

    /*
        This function calls the websdk util function to return a ccm token
    */
    async getChronicCareToken() {
        const response = await fetchChronicCareToken(
            this.generateWebSDKParams()
        ).catch(exception => {
            return this.redirectToLoginIfNeed(exception);
        });

        return response;
    },

    /*
        This function is to support the refreshToken() method
    */
    refreshHelper(refreshToken) {
        if (refreshToken) {
            // set up the authorization token refresh to happen 5 minutes before its set to expire
            // the "expires" time comes in seconds from the server so we manipulate it to units we need for setTimeout which is ms

            if (!expires) {
                expires = parseInt(this.getCurrentCcmAccessTokenTtl(), 10);
            }

            refreshTimeout = window.setTimeout(() => {
                OneAppUserAPI.refreshToken();
            }, expires * 1000 - 300000); // 300,000ms = 5 minutes

            // save the refresh token in a native cookie so that it can be used to check login status across Livongo entities
            CommonUtils.setCookie({
                key: REFRESH_TOKEN_COOKIE,
                value: refreshToken,
            });
        } else {
            StorageUtils.remove({
                key: REFRESH_TOKEN_COOKIE,
                type: 'cookie',
                useNative: true,
            });
        }

        return refreshToken;
    },

    /*
        This function will invalidate the previous logged in session's ccm token if the
        user never logs out correctly and also remove all cookies
    */
    async removeOneAppCookies() {
        const usePrevMember = true;

        if (!isNil(OneAppUserAPI.getPrevMemberCcmAccessToken())) {
            await chronicCareTokenLogout(
                OneAppUserAPI.generateWebSDKParams(usePrevMember)
            ).catch(exception => {
                // Do nothing
            });
        }

        StorageUtils.remove({
            key: IS_TDOC_TOKEN_INVALID,
        });
        OneAppUserAPI.removeCookieForMember(memberType.linked);
        OneAppUserAPI.removeCookieForMember(memberType.unlinked);
    },

    /*
        This function creats the ccm token and starts the refreshToken() interval
    */
    async updateAuthorization(ccmToken) {
        let response = ccmToken;

        if (!response) {
            response = await this.getChronicCareToken();
        }
        const accessToken = response?.access_token;
        const lastActivityTimestamp = response?.last_activity_timestamp;
        const refreshToken = response?.refresh_token;

        expires = response?.expires_in;

        // this cookie is needed to properly retrieve food log images because they require this cookie to be present when being loaded from the server
        // we also expose it to other Livongo entities so they can retrieve it and use it for retaining a user's session
        if (accessToken) {
            this.setCurrentCcmAccessToken(accessToken);
            timestamp = lastActivityTimestamp;
            UserUtils.updateHeaders({Authorization: `Bearer ${accessToken}`});
        }

        this.refreshHelper(refreshToken);

        if (expires) {
            CommonUtils.setLocalStorage({
                key: EXPIRATION_TIME,
                value: expires,
            });
        }

        // Persist teladoc generated cookies to have same expiration as other cookies
        this.persistMemberWebsiteGeneratedCookies();

        return response;
    },

    /*
        This function is used to refresh the ccm token
    */
    async refreshToken() {
        const response = await this.getChronicCareToken();

        if (response) {
            const store = getStore();

            if (!isUndefined(store) && timestamp) {
                store.dispatch(userUpdate({lastActivityTimestamp: timestamp}));
            }

            return this.updateAuthorization(response);
        }
    },

    /*
        This helper function clears all cookies and redirect the user back to the login page
        when they log out
    */
    telemedLogout(memberHost) {
        window.clearTimeout(refreshTimeout);

        CommonUtils.removeCookie(MEMBER_ID);
        CommonUtils.removeCookie(TDOC_AUTH_TOKEN);
        CommonUtils.removeCookie(IMPERSONATION);
        StorageUtils.remove({
            key: IMPERSONATION,
            type: 'session',
            useNative: true,
        });
        OneAppUserAPI.removeOneAppCookies();

        redirectToLoginPage(true, memberHost);
    },

    /*
        This function logs the user out
    */
    async logout(programs, memberHost) {
        if (
            ProgramUtils.userHasActiveCCMProgram(programs) ||
            ProgramUtils.userHasActiveMHProgram(programs)
        ) {
            await chronicCareTokenLogout(
                OneAppUserAPI.generateWebSDKParams()
            ).catch(exception => {
                // Do nothing
            });
            ChatAPI.disconnect();
            UserUtils.logout();
        }
        OneAppUserAPI.removeOneAppCookies();
        OneAppUserAPI.telemedLogout(memberHost);
    },

    /*
        This function calls the api to build the Program dropdown menu
    */
    getMemberNavigation() {
        return fetchMemberNavigation(this.generateWebSDKParams())
            .then(response => {
                return response;
            })
            .catch(exception => {
                throw exception;
            });
    },

    hasUserMHGuideAccess() {
        return this.getCurrentCcmAccessToken() && this.getCcmRefreshToken()
            ? myStrengthAxiosInstance
                  .post(
                      `/public/v3/auth/web`,
                      `{
                          "authToken":"${this.getCurrentCcmAccessToken()}",
                          "reauthToken":"${this.getCcmRefreshToken()}"
                      }`,
                      {
                          headers: {
                              'Content-Type': 'application/json',
                          },
                      }
                  )
                  .then(({data}) => Boolean(data.response.coachingAssignment))
                  .catch(() => {
                      return false;
                  })
            : Promise.resolve(false);
    },
};

export default OneAppUserAPI;
